export default {

	// api请求地址
	baseUrl: 'https://168.gimp.cn',
	// baseUrl: 'https://test.com',

	// 图片域名
	imgDomain: 'https://168.gimp.cn',

	// 腾讯地图key
	mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',

	// 客服
	webSocket: 'wss://168.gimp.cn/wss',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: `-----BEGIN PUBLIC KEY-----
	MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAugrL6uwMxKhOW1z7KpuQ
	88KBMD/5FXFRLxwfj53SnbBER5RVfbJYizosXcbBk6COlWnraXSHUT/TGTeGVXsi
	tp2IArZ8v834TD8nwKgwY4emgNtDd+0MEyw8iC7B/YPWS/vuwjSEm3fXytTt8qdS
	nYdBrzxjy0N3sUjr9MflOZExtBDQOVMkf8/tVvjIvzK0ncoREc5/HIe7bZ03V43m
	mhPlLxuuSUxhRNfRrPQEKU4kLXAoK2sYK4Sp3bo696q6MF3qRGPwsDkqfH1vnBbH
	DdHW1i8+Jxy6BaOV+dC1h1SGI+0FXIEiYaUPfM290HPTMOn69ntW6eSnaYbF4C14
	w2sS5RcieRLsd4ijBksbDggyyt8VdfR8+0KFUoz+n1tpwVrK84qly5PTE5bK+hEh
	NdD8YPWhGUMcliWOkM5CcOgMTW+jH2bi4k2f4zMukm59YrJEAuEc3aP4GPuU+3GS
	TbEMulnlVi6XB8HmihLPt8xk87GLoTeBkKAzWDlcUcoJgy5Oc0hmzIVLl1SOISKt
	Am0c3VbmMAIw05crjXng5RrKmsS3Ca212Z41chLAkIS/OcxBy6s65KIcQgxr6RbE
	h6X7sSORFgio82LJaIeJpl60DjvNy6G8rbV2mi7xHxW2je8UFu9dcbtSPHJtH9Aq
	pwI9414tc0BTpYfSVRv1LiECAwEAAQ==
	-----END PUBLIC KEY-----`,
}
